import React from 'react'
import { ExpertAvatar as ExpertAvatarFromBluxome } from '@deal/bluxome'
import { expertForAvatar } from '#src/app/services/avatars/expertForAvatar'
import { BusinessUserForExpertAvatarFragment } from './ExpertAvatar.generated'

type ExpertAvatarFromBluxomeProps = React.ComponentPropsWithoutRef<typeof ExpertAvatarFromBluxome>

interface ExpertAvatarProps
  extends Omit<
    ExpertAvatarFromBluxomeProps,
    'name' | 'expertise' | 'bio' | 'reviewCount' | 'customerCount' | 'image' | 'rating'
  > {
  expert: BusinessUserForExpertAvatarFragment
}

export const ExpertAvatar: React.FC<ExpertAvatarProps> = ({ expert, size = 'base', ...props }) => {
  const expertProps = expertForAvatar(expert)

  return <ExpertAvatarFromBluxome {...expertProps} {...props} size={size} />
}
