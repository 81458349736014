import React, { useState } from 'react'
import { ConsumerRichCardEvents, useRichCardEventEmitter } from '@deal/chat-firebase'
import { ModalPath } from '#src/app/components/Path/ModalPath'
import { useNotifyStartRequestPathAbortedMutation } from './Mutations.generated'

const StartRequestPathModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pathGraphFlowId, setPathGraphFlowId] = useState('')
  const [notifyStartRequestPathAbortedMutation] = useNotifyStartRequestPathAbortedMutation({
    variables: {
      input: { id: pathGraphFlowId }
    },
    onCompleted: () => {
      setIsModalOpen(false)
    }
  })

  const richCardEventEmitter = useRichCardEventEmitter()

  richCardEventEmitter?.addListener(ConsumerRichCardEvents.START_REQUEST_PATH, eventData => {
    setPathGraphFlowId(eventData.pathGraphFlowId)
    setIsModalOpen(true)
  })
  if (pathGraphFlowId) {
    return (
      <ModalPath
        isOpen={isModalOpen}
        onClose={() => notifyStartRequestPathAbortedMutation()}
        defaultPathGraphFlowId={pathGraphFlowId}
        onReturnToPreviousContext={() => setIsModalOpen(false)}
      />
    )
  }
  return null
}

export default StartRequestPathModal
