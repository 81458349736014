import React, { useState } from 'react'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import { getOperationName } from '@apollo/client/utilities'
import { Button, Modal, TextField } from '@deal/components'
import getWishlistSlug from '#src/app/routes/wishlists/util/getWishlistSlug'
import { LeadsForCurationsListDocument } from '#src/app/routes/curations/pages/Curation/Curation.generated'
import { WishlistCreatedEvent } from '#src/app/events/WishlistCreatedEvent'
import { useIdentityContext } from '#src/app/containers/Identity'
import { DesktopHeaderDocument } from '#src/app/components/Header/desktop/DesktopHeader.generated'
import {
  CreateWishlistForCreateWishlistFormMutation,
  useAddAddressWithValidationForCreateWishlistFormMutation,
  useCreateWishlistForCreateWishlistFormMutation,
  useDeleteAddressForCreateWishlistFormMutation
} from './CreateWishlist.generated'
import ShippingAddressForm, { ShippingAddress } from '../ShippingAddressForm'
import styles from './styles.css'

interface CreateWishlistFormProps {
  isOpen: boolean
  onRequestClose: () => void
  onCompleted?: (data: CreateWishlistForCreateWishlistFormMutation) => void
}

const CreateWishlistForm: React.FC<CreateWishlistFormProps> = ({
  isOpen,
  onRequestClose,
  onCompleted
}) => {
  const { myself } = useIdentityContext()

  const initialWishlistTitle = 'My registry'
  const [wishlistTitle, setWishlistTitle] = useState(initialWishlistTitle)
  const [createWishlist, { loading: createWishlistLoading }] =
    useCreateWishlistForCreateWishlistFormMutation({
      onCompleted: ({ createWishlist: { wishlist } }) => {
        if (wishlist) {
          window.tracking?.track(
            new WishlistCreatedEvent({
              wishlist_id: wishlist.id
            })
          )
        }
      }
    })

  const [showShippingDetailsForm, setShowShippingDetailsForm] = useState(false)
  const emptyAddress: ShippingAddress = {
    addresseeName: '',
    country: undefined,
    line1: undefined,
    line2: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
    phoneNumber: undefined,
    reusableAddress: false
  }
  const [address, setAddress] = useState(emptyAddress)
  const [showValidation, setShowValidation] = useState(false)

  const [createAddress, { data: createAddressData, loading: createAddressLoading }] =
    useAddAddressWithValidationForCreateWishlistFormMutation()
  const [deleteAddress] = useDeleteAddressForCreateWishlistFormMutation()

  const resetForm = () => {
    setWishlistTitle(initialWishlistTitle)
    setAddress(emptyAddress)
    setShowShippingDetailsForm(false)
    setShowValidation(false)
  }

  const showUnverifiedAddressWarning =
    createAddressData?.addAddressWithValidation.error?.__typename ===
    'InvalidAddressAddAddressWithValidationError'

  const handleFormSubmitted: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    if (getWishlistSlug(wishlistTitle) === 'manage') {
      toast.error('Please choose a different name for your registry.')
      return
    }

    if (!showShippingDetailsForm) {
      createWishlist({
        variables: {
          title: wishlistTitle
        },
        // ! this refetch method vs the one further down act very slightly differently
        // ! refetching causes rollbar errors when the query hasn't been run before (cache is clearred, first time to the site, etc). Is that an issue?
        refetchQueries: [
          getOperationName(DesktopHeaderDocument)!,
          getOperationName(LeadsForCurationsListDocument)!
        ],
        onCompleted: createWishlistResultData => {
          onRequestClose()
          resetForm()
          onCompleted && onCompleted(createWishlistResultData)
        },
        onError: () => {
          toast.error('Error creating the wishlist, please try again.')
        }
      })
    }

    if (showShippingDetailsForm) {
      const {
        addresseeName,
        reusableAddress,
        line1,
        line2,
        city,
        state,
        country,
        postalCode,
        phoneNumber
      } = address

      if (
        !addresseeName.trim().includes(' ') ||
        !line1 ||
        !city ||
        !state ||
        !postalCode ||
        !country
      ) {
        toast.error('Please double check all fields for errors.')
        setShowValidation(true)
        return
      } else {
        setShowValidation(false)
      }

      createAddress({
        variables: {
          input: {
            addressField: {
              addresseeName,
              reusableAddress,
              line1,
              line2,
              city,
              state,
              country,
              postalCode,
              phoneNumber
            },
            addressUpdateOption: {
              // If we're currently showing the unverified address warning, submitting the form again
              //   is the user acknowledging that they are intentionally submitting an address we do
              //   not recognize.
              ignoreAddressValidationResult: showUnverifiedAddressWarning
            }
          }
        },
        onCompleted: addAddressResultData => {
          if (addAddressResultData.addAddressWithValidation.address?.id) {
            createWishlist({
              variables: {
                title: wishlistTitle,
                addressId: addAddressResultData.addAddressWithValidation.address.id
              },
              refetchQueries: [DesktopHeaderDocument, LeadsForCurationsListDocument],
              onCompleted: createWishlistResultData => {
                if (!createWishlistResultData.createWishlist.wishlist) {
                  // If we add an address book to this component, we may need to handle the
                  // delete address with a `cache.evict`
                  // (see example: src/app/routes/checkout/components/ShippingContainer/v2/edit-address/index.tsx)

                  deleteAddress({
                    variables: {
                      input: {
                        userId: myself?.id,
                        addressId: addAddressResultData.addAddressWithValidation.address?.id
                      }
                    }
                  })
                }
                onRequestClose()
                resetForm()
                onCompleted && onCompleted(createWishlistResultData)
              },
              onError: () => {
                toast.error('Error creating the wishlist, please try again.')

                deleteAddress({
                  variables: {
                    input: {
                      userId: myself?.id,
                      addressId: addAddressResultData.addAddressWithValidation.address?.id
                    }
                  }
                })
              }
            })
          } else if (
            addAddressResultData.addAddressWithValidation.error?.__typename !==
            'InvalidAddressAddAddressWithValidationError'
          ) {
            toast.error('There was a problem creating your registry, please try again.')
          }
        },
        onError: () => {
          toast.error('There was a problem creating your registry, please try again.')
        }
      })
    }
  }

  const loading = createWishlistLoading || createAddressLoading

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      hideCloseButton
      mobilePosition="bottom"
      title="Create your registry"
      actionVariant="secondary"
      primaryAction={{
        label: showUnverifiedAddressWarning ? 'Continue' : 'Create registry',
        type: 'submit',
        disabled: loading,
        form: 'create-registry'
      }}
      tertiaryAction={{
        label: 'Cancel',
        type: 'button',
        disabled: loading,
        onClick: () => {
          onRequestClose()
          resetForm()
        }
      }}
    >
      <form
        id="create-registry"
        onSubmit={handleFormSubmitted}
        className={classNames(styles.formContainer, {
          [styles.bottomPaddingLarge]: !showShippingDetailsForm
        })}
      >
        <TextField
          label="Name your registry"
          value={wishlistTitle}
          onChange={e => setWishlistTitle(e.target.value)}
        />
        {!showShippingDetailsForm && (
          <Button
            variant="neutral-dark-ghost"
            size="xsmall"
            className={styles.addressButton}
            onClick={() => setShowShippingDetailsForm(true)}
          >
            Add a shipping address for gifts
          </Button>
        )}
        {showShippingDetailsForm && (
          <div>
            <div className={styles.shippingHeadingContainer}>
              <span className={styles.shippingHeading}>Shipping Address</span>
              <span
                className={styles.shippingCancel}
                onClick={() => {
                  setShowShippingDetailsForm(false)
                }}
              >
                Add this later
              </span>
            </div>
            <ShippingAddressForm
              shippingAddress={address}
              onShippingAddressChanged={setAddress}
              showValidation={showValidation}
              showUnverifiedAddressWarning={showUnverifiedAddressWarning}
              enableSaveAddress={false}
            />
          </div>
        )}
      </form>
    </Modal>
  )
}

export default CreateWishlistForm
