import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { ApolloConsumer } from '@apollo/client'
import { Application, ChatProvider, ToastNotificationType } from '@deal/chat-firebase'
import { MessagingUserType } from '#src/generated/types'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import { IdentityConsumer } from '#src/app/containers/Identity'
import { AnalyticsContext } from '#src/app/containers/Analytics'
import config from '#src/app/config'
import { ProductQuizMessage } from '#src/app/components/Messages'
import {
  GetMessagingUsersDocument,
  GetMessagingUsersQuery as GetMessagingUsersQueryType,
  GetMessagingUsersQueryVariables
} from './getMessagingUsers.generated'
import RichCardEventHandler from '../RichCardEventHandler'
import '@deal/chat-firebase/es/index.css'

export const ConsumerChatProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const analytics = useContext(AnalyticsContext)
  const userAgent = useUserAgentContext()

  return (
    <IdentityConsumer>
      {({ myself }) => {
        return (
          /**
           * TODO: Update `ChatProvider` to have more React-idiomatic props (i.e. non-imperative APIs):
           *
           *   - `onLoadUserProfiles`, `userProfiles` instead of `fetchUserProfiles`
           *   - `onAuthenticate`, `authenticationToken` instead of `authenticate`
           */
          <ApolloConsumer>
            {client => (
              <ChatProvider
                applicationContext={Application.CONSUMER}
                tracking={analytics!}
                userAgent={userAgent}
                onToastNotification={(type, message) => {
                  if (type === ToastNotificationType.ERROR) {
                    toast.error(message)
                  } else {
                    toast.success(message)
                  }
                }}
                firebaseApiKey={config.get('firebase.api_key')}
                firebaseProjectId={config.get('firebase.project_id')}
                tenantId="AgAAAdQAAAAAAAAAAAAAAAAAAAAAAA"
                fetchUserProfiles={userIds =>
                  client
                    .query<GetMessagingUsersQueryType, GetMessagingUsersQueryVariables>({
                      query: GetMessagingUsersDocument,
                      variables: { ids: userIds }
                    })
                    .then(({ data }) =>
                      data.messagingUsers.map(messagingUser => {
                        return {
                          userId: messagingUser.id,
                          vanityId:
                            messagingUser.profile.__typename === 'BusinessUser'
                              ? messagingUser.profile.vanityId || undefined
                              : undefined,
                          displayName: messagingUser.displayName,
                          internalMessagingUser: messagingUser.type === MessagingUserType.USER,
                          type: messagingUser.type,
                          avatarUrl: messagingUser.avatar ? messagingUser.avatar.url : undefined,
                          expertRating:
                            (messagingUser.profile.__typename === 'BusinessUser' &&
                              messagingUser.profile.expertStats &&
                              messagingUser.profile.expertStats.averageRating) ||
                            undefined
                        }
                      })
                    )
                }
                userId={myself && myself.user.messagingUser.id}
                impersonating={myself ? myself.realUser.id !== myself.user.id : false}
                customMessageComponents={[ProductQuizMessage]}
                isRebranded
              >
                <>
                  <RichCardEventHandler />
                  {children}
                </>
              </ChatProvider>
            )}
          </ApolloConsumer>
        )
      }}
    </IdentityConsumer>
  )
}
