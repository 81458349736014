// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type NotifyStartRequestPathAbortedMutationVariables = Types.Exact<{
  input: Types.NotifyPathGraphFlowAbortedInput;
}>;


export type NotifyStartRequestPathAbortedMutation = { __typename: 'Mutation', notifyPathGraphFlowAborted: { __typename: 'NotifyPathGraphFlowAbortedPayload', pathGraphFlow: { __typename: 'PathGraphFlow', id: any } } };


export const NotifyStartRequestPathAbortedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NotifyStartRequestPathAborted"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NotifyPathGraphFlowAbortedInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notifyPathGraphFlowAborted"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pathGraphFlow"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type NotifyStartRequestPathAbortedMutationFn = Apollo.MutationFunction<NotifyStartRequestPathAbortedMutation, NotifyStartRequestPathAbortedMutationVariables>;
export type NotifyStartRequestPathAbortedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<NotifyStartRequestPathAbortedMutation, NotifyStartRequestPathAbortedMutationVariables>, 'mutation'>;

    export const NotifyStartRequestPathAbortedComponent = (props: NotifyStartRequestPathAbortedComponentProps) => (
      <ApolloReactComponents.Mutation<NotifyStartRequestPathAbortedMutation, NotifyStartRequestPathAbortedMutationVariables> mutation={NotifyStartRequestPathAbortedDocument} {...props} />
    );
    

/**
 * __useNotifyStartRequestPathAbortedMutation__
 *
 * To run a mutation, you first call `useNotifyStartRequestPathAbortedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyStartRequestPathAbortedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyStartRequestPathAbortedMutation, { data, loading, error }] = useNotifyStartRequestPathAbortedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotifyStartRequestPathAbortedMutation(baseOptions?: Apollo.MutationHookOptions<NotifyStartRequestPathAbortedMutation, NotifyStartRequestPathAbortedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotifyStartRequestPathAbortedMutation, NotifyStartRequestPathAbortedMutationVariables>(NotifyStartRequestPathAbortedDocument, options);
      }
export type NotifyStartRequestPathAbortedMutationHookResult = ReturnType<typeof useNotifyStartRequestPathAbortedMutation>;
export type NotifyStartRequestPathAbortedMutationResult = Apollo.MutationResult<NotifyStartRequestPathAbortedMutation>;
export type NotifyStartRequestPathAbortedMutationOptions = Apollo.BaseMutationOptions<NotifyStartRequestPathAbortedMutation, NotifyStartRequestPathAbortedMutationVariables>;