import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import keyBy from 'lodash/keyBy'
import { useHistory } from '@deal/router'
import {
  ConsumerRichCardEvents,
  RichCardEventData,
  SharedRichCardEvents,
  useRichCardEventEmitter
} from '@deal/chat-firebase'
import { AttributeType } from '#src/generated/types'
import { getSellableUrl } from '#src/app/services/sellable'
import { formatPathUrl, useCreateRequestPath } from '#src/app/services/path'
import { isMobileiOS } from '#src/app/services/mobile'
import { useSearchResultsLocation } from '#src/app/hooks/useSearchResultsLocation'
import { useProductListingLocation } from '#src/app/hooks/useProductListingLocation'
import { useUserAgentContext } from '#src/app/containers/UserAgent'
import getOnboardingPathSlug from '../../../services/path/helpers/getOnboardingPathSlug'
import StartRequestPathModal from './StartRequestPathModal'
import RichCardReferralModal from './RichCardReferralModal'
import RichCardExpertRatingModal from './RichCardExpertRatingModal'
import RichCardExpertCuratedSearch from './RichCardExpertCuratedSearch'
import CreateWishlistModal from './CreateWishlistModal'
import AddMembershipToOrder from './AddMembershipToOrder'

interface ChatEventRegisterListener {
  event: ConsumerRichCardEvents | SharedRichCardEvents
  callback: (eventData: RichCardEventData) => void
}

const ChatEventRegisterListener: React.FC<React.PropsWithChildren<ChatEventRegisterListener>> = ({
  event,
  callback
}) => {
  const richCardEventEmitter = useRichCardEventEmitter()

  useEffect(() => {
    richCardEventEmitter?.addListener(event, callback)

    return () => richCardEventEmitter?.removeListener(event)
  })

  return null
}

/**
 * This is the event handler for rich card events fired from within chat-firebase. Since this
 * relies on context from that Provider, it **must** be nested within the ChatProvider when it
 * is included. This component will register the necessary listeners on render.
 */
const ChatEventHandler: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory()
  const searchLocation = useSearchResultsLocation()
  const productListingLocation = useProductListingLocation()
  const userAgent = useUserAgentContext()

  const [createPath] = useCreateRequestPath({
    onPathCreated: ({ pathGraphFlow, pathGraphFlowSlug, nodeSlugOrId }) => {
      if (pathGraphFlow) {
        history.push(
          formatPathUrl({
            pathGraphFlowId: pathGraphFlow.pathGraphFlowId,
            nodeSlugOrId,
            pathGraphFlowSlug
          })
        )
      } else {
        throw new Error('Error creating path for `ChatEventHandler` component!')
      }
    },
    onError: ({ errorMessage }) => {
      toast.error(errorMessage)
      throw new Error(errorMessage)
    }
  })

  return (
    <>
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.VIEW_PRODUCT_CURATION}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.VIEW_PRODUCT_CURATION) {
            const newLocationPath = `/curations/products/${eventData.leadId}`
            // if we're already on this curation page, reload it to see new items
            if (history.location.pathname === newLocationPath) {
              location.reload()
            }
            history.push(newLocationPath)
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.VIEW_EXPERIENCE_CURATION}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.VIEW_EXPERIENCE_CURATION) {
            history.push(`/curations/experiences/review/${eventData.leadId}`)
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.VIEW_EXPERIENCE_ORDER}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.VIEW_EXPERIENCE_ORDER) {
            history.push(`/curations/experiences/details/${eventData.orderId}`)
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.VIEW_ORDER_SUPPORT}
        callback={() => {
          history.push(`/support/order-support`)
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.VIEW_ORDER}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.VIEW_ORDER) {
            history.push(`/account/orders/${eventData.orderId}`)
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.VIEW_ORDER_CHECKOUT}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.VIEW_ORDER_CHECKOUT) {
            history.push(`/checkout/${eventData.orderId}`)
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.GIFT_CARD_ORDER}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.GIFT_CARD_ORDER) {
            history.push(`/account/gift-cards/buy/${eventData.orderId}?amount=${eventData.amount}`)
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.EXPERT_TIP_ORDER}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.EXPERT_TIP_ORDER) {
            if (eventData.isCustomTip) {
              history.push(`/checkout/${eventData.orderId}/payment?customTip=true`)
            } else {
              history.push(`/checkout/${eventData.orderId}/payment`)
            }
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.VIEW_EXPERT_PROFILE}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.VIEW_EXPERT_PROFILE) {
            history.push(`/experts/${eventData.vanityIdOrId}`)
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.PATH_CREATE_FROM_CATEGORY_WITH_EXPERT}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.PATH_CREATE_FROM_CATEGORY_WITH_EXPERT) {
            createPath({
              pathSelector: {
                categorySlugHierarchyLookup: eventData.categorySlug
              },
              initialAttributes: [
                {
                  name: 'presentedExpertId',
                  type: AttributeType.ID,
                  idValue: eventData.expertId
                }
              ],
              trackingCodes: {
                ctaName: eventData.sourceKey,
                sourceKey: eventData.sourceKey
              }
            })
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.PATH_REQUESTED_BY_EXPERT}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.PATH_REQUESTED_BY_EXPERT) {
            createPath({
              pathSelector: {
                pathSchemaSlug: getOnboardingPathSlug()
              },
              initialAttributes: [
                {
                  name: 'expertId',
                  type: AttributeType.ID,
                  idValue: eventData.expertId
                }
              ],
              trackingCodes: {
                ctaName: eventData.sourceKey,
                sourceKey: eventData.sourceKey
              }
            })
          }
        }}
      />
      <ChatEventRegisterListener
        event={SharedRichCardEvents.VIEW_SELLABLE}
        callback={eventData => {
          if (eventData.type === SharedRichCardEvents.VIEW_SELLABLE) {
            history.push(
              getSellableUrl({
                __typename: 'Sellable',
                id: eventData.id,
                slug: eventData.slug,
                type: eventData.sellableType,
                friendlyId: eventData.friendlyId
              })
            )
          }
        }}
      />
      <ChatEventRegisterListener
        event={SharedRichCardEvents.VIEW_SELLABLE_TOP_LIST}
        callback={eventData => {
          if (eventData.type === SharedRichCardEvents.VIEW_SELLABLE_TOP_LIST) {
            history.push(`/most-recommended/${eventData.friendlyId}/${eventData.slug}`)
          }
        }}
      />
      <ChatEventRegisterListener
        event={SharedRichCardEvents.VIEW_ARTICLE}
        callback={eventData => {
          if (eventData.type === SharedRichCardEvents.VIEW_ARTICLE) {
            history.push(`/journal/${eventData.friendlyId}`)
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.VIEW_SELLABLE_SEARCH_PAGE}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.VIEW_SELLABLE_SEARCH_PAGE) {
            if (eventData.keywords?.length !== 0) {
              history.push(
                searchLocation({
                  categorySlug: eventData.categorySlug,
                  keywords: eventData.keywords,
                  selections: keyBy(eventData.facets, 'name')
                })
              )
            } else {
              history.push(
                productListingLocation({
                  categorySlug: eventData.categorySlug,
                  selections: keyBy(eventData.facets, 'name')
                })
              )
            }
          }
        }}
      />
      <ChatEventRegisterListener
        event={ConsumerRichCardEvents.FITTING_INVITATION_FOR_CONSUMER}
        callback={eventData => {
          if (eventData.type === ConsumerRichCardEvents.FITTING_INVITATION_FOR_CONSUMER) {
            if (eventData.errorMessage) {
              toast.error(eventData.errorMessage)
            } else if (isMobileiOS(userAgent)) {
              // mobile ios will block any request to open a new window in an async operation.
              // instead open the link in the same tab
              window.open(eventData.link, '_top')
            } else {
              window.open(eventData.link, '_blank')
            }
          }
        }}
      />
      <RichCardExpertCuratedSearch />
      <AddMembershipToOrder />
      <RichCardReferralModal />
      <RichCardExpertRatingModal />
      <CreateWishlistModal />
      <StartRequestPathModal />
    </>
  )
}

export default ChatEventHandler
