import React from 'react'
import { toast } from 'react-toastify'
import { useHistory } from '@deal/router'
import { SharedRichCardEvents, useRichCardEventEmitter } from '@deal/chat-firebase'
import { displayFacetsToFacetSelectionsMap } from '#src/app/services/search'
import { useProductListingLocation } from '#src/app/hooks/useProductListingLocation'
import { useExpertCuratedSearchForRichCardExpertCuratedSearchLazyQuery } from './RichCardExpertCuratedSeach.generated'

const RichCardExpertCuratedSearch: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory()
  const productListingLocation = useProductListingLocation()

  const richCardEventEmitter = useRichCardEventEmitter()
  const [expertCuratedSearch] = useExpertCuratedSearchForRichCardExpertCuratedSearchLazyQuery({
    onCompleted: ({ expertCuratedSearch }) => {
      if (expertCuratedSearch) {
        const facetedCategoryLink = productListingLocation({
          categorySlug: expertCuratedSearch.category.slug,
          selections: displayFacetsToFacetSelectionsMap(expertCuratedSearch.decoratedFacets),
          // TODO: Update this once web-tracking can be bumped in consumer
          entryPoint: 'nav-browse'
        })

        history.push(facetedCategoryLink)
      } else {
        toast.error('There was a problem finding that search! Ask your expert for some help!')
      }
    }
  })

  richCardEventEmitter?.addListener(SharedRichCardEvents.VIEW_EXPERT_CURATED_SEARCH, eventData => {
    expertCuratedSearch({
      variables: {
        id: eventData.expertCuratedSearchId
      }
    })
  })

  return null
}

export default RichCardExpertCuratedSearch
