// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type GetMessagingUsersQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['AnyId']['input']> | Types.Scalars['AnyId']['input'];
}>;


export type GetMessagingUsersQuery = { __typename: 'Query', messagingUsers: Array<{ __typename: 'MessagingUser', id: any, displayName: string, type: Types.MessagingUserType, avatar?: { __typename: 'MediaFile', id: any, url: string } | null, profile: { __typename: 'BusinessUser', id: any, vanityId?: string | null, expertStats?: { __typename: 'ExpertStats', averageRating?: number | null } | null } | { __typename: 'InternalMessagingUser' } | { __typename: 'Merchant' } | { __typename: 'OnboardingSpecialistUser' } | { __typename: 'Operator' } | { __typename: 'SupportMessagingUser' } | { __typename: 'User' } }> };


export const GetMessagingUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMessagingUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"ids"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnyId"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messagingUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"ids"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"vanityId"}},{"kind":"Field","name":{"kind":"Name","value":"expertStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"averageRating"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type GetMessagingUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMessagingUsersQuery, GetMessagingUsersQueryVariables>, 'query'> & ({ variables: GetMessagingUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMessagingUsersComponent = (props: GetMessagingUsersComponentProps) => (
      <ApolloReactComponents.Query<GetMessagingUsersQuery, GetMessagingUsersQueryVariables> query={GetMessagingUsersDocument} {...props} />
    );
    

/**
 * __useGetMessagingUsersQuery__
 *
 * To run a query within a React component, call `useGetMessagingUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagingUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagingUsersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMessagingUsersQuery(baseOptions: Apollo.QueryHookOptions<GetMessagingUsersQuery, GetMessagingUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagingUsersQuery, GetMessagingUsersQueryVariables>(GetMessagingUsersDocument, options);
      }
export function useGetMessagingUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagingUsersQuery, GetMessagingUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagingUsersQuery, GetMessagingUsersQueryVariables>(GetMessagingUsersDocument, options);
        }
export function useGetMessagingUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMessagingUsersQuery, GetMessagingUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMessagingUsersQuery, GetMessagingUsersQueryVariables>(GetMessagingUsersDocument, options);
        }
export type GetMessagingUsersQueryHookResult = ReturnType<typeof useGetMessagingUsersQuery>;
export type GetMessagingUsersLazyQueryHookResult = ReturnType<typeof useGetMessagingUsersLazyQuery>;
export type GetMessagingUsersSuspenseQueryHookResult = ReturnType<typeof useGetMessagingUsersSuspenseQuery>;
export type GetMessagingUsersQueryResult = Apollo.QueryResult<GetMessagingUsersQuery, GetMessagingUsersQueryVariables>;