import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { ConsumerRichCardEvents, useRichCardEventEmitter } from '@deal/chat-firebase'
import CreateWishlistForm from '#src/app/components/CreateWishlistForm'

const CreateWishlistModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const richCardEventEmitter = useRichCardEventEmitter()

  richCardEventEmitter?.addListener(ConsumerRichCardEvents.CREATE_WISHLIST, () => {
    setIsModalOpen(true)
  })

  if (isModalOpen) {
    return (
      <>
        {isModalOpen && (
          <CreateWishlistForm
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            onCompleted={data =>
              history.push(`/wishlist/${data.createWishlist.wishlist?.id}/manage`)
            }
          />
        )}
      </>
    )
  }

  return null
}

export default CreateWishlistModal
