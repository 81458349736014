// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type StartSystemCuratedItemSurveyMutationVariables = Types.Exact<{
  id: Types.Scalars['SystemCuratedItemSurveyId']['input'];
}>;


export type StartSystemCuratedItemSurveyMutation = { __typename: 'Mutation', startSystemCuratedItemSurvey: { __typename: 'StartSystemCuratedItemSurveyPayload', error?: string | null, data?: { __typename: 'SystemCuratedItemSurvey', id: any, startedAt?: any | null } | null } };


export const StartSystemCuratedItemSurveyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StartSystemCuratedItemSurvey"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SystemCuratedItemSurveyId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startSystemCuratedItemSurvey"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"startedAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode;
export type StartSystemCuratedItemSurveyMutationFn = Apollo.MutationFunction<StartSystemCuratedItemSurveyMutation, StartSystemCuratedItemSurveyMutationVariables>;
export type StartSystemCuratedItemSurveyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartSystemCuratedItemSurveyMutation, StartSystemCuratedItemSurveyMutationVariables>, 'mutation'>;

    export const StartSystemCuratedItemSurveyComponent = (props: StartSystemCuratedItemSurveyComponentProps) => (
      <ApolloReactComponents.Mutation<StartSystemCuratedItemSurveyMutation, StartSystemCuratedItemSurveyMutationVariables> mutation={StartSystemCuratedItemSurveyDocument} {...props} />
    );
    

/**
 * __useStartSystemCuratedItemSurveyMutation__
 *
 * To run a mutation, you first call `useStartSystemCuratedItemSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSystemCuratedItemSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSystemCuratedItemSurveyMutation, { data, loading, error }] = useStartSystemCuratedItemSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartSystemCuratedItemSurveyMutation(baseOptions?: Apollo.MutationHookOptions<StartSystemCuratedItemSurveyMutation, StartSystemCuratedItemSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSystemCuratedItemSurveyMutation, StartSystemCuratedItemSurveyMutationVariables>(StartSystemCuratedItemSurveyDocument, options);
      }
export type StartSystemCuratedItemSurveyMutationHookResult = ReturnType<typeof useStartSystemCuratedItemSurveyMutation>;
export type StartSystemCuratedItemSurveyMutationResult = Apollo.MutationResult<StartSystemCuratedItemSurveyMutation>;
export type StartSystemCuratedItemSurveyMutationOptions = Apollo.BaseMutationOptions<StartSystemCuratedItemSurveyMutation, StartSystemCuratedItemSurveyMutationVariables>;