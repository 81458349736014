import React, { useContext } from 'react'
import { Image } from '@deal/components'
import {
  FirebaseMessageModel,
  MessageBubble,
  MessageBubbleText,
  MessageComponentProps
} from '@deal/chat-firebase'
import {
  ConsumerSellableReactionRating,
  ConsumerSellableReactionSource,
  MessageRichCardType,
  SystemCuratedItemSurveyTerminationReason
} from '#src/generated/types'
import { getSellableEcommerceProperties } from '#src/app/services/tracking'
import { formatSellablePricing } from '#src/app/services/sellable'
import { ProductRatedEvent } from '#src/app/events/ProductRatedEvent'
import { AnalyticsContext } from '#src/app/containers/Analytics'
import SellableReactions from '#src/app/components/SellableReactions'
import { TerminateSystemCuratedItemSurveyComponent } from './TerminateSystemCuratedItemSurvey.generated'
import { SystemCuratedItemSurveyComponent } from './SystemCuratedItemSurvey.generated'
import { SystemCuratedItemFragment } from './SystemCuratedItem.generated'
import { StartSystemCuratedItemSurveyComponent } from './StartSystemCuratedItemSurvey.generated'
import { SaveConsumerSellableReactionComponent } from './ReactionFeedback/SaveConsumerSellableReactions.generated'
import SellableLink from '../../SellableLink'
import TerminationState from './TerminationState'
import { getRecommendationSource } from './ReactionFeedback'
import styles from './styles.css'

interface State {
  sellableToShow: number
  consecutiveSkips: number
}

export default class ProductQuizMessage extends React.Component<MessageComponentProps, State> {
  public static handlesMessage = (message: FirebaseMessageModel) => {
    const { body } = message.parts[0]
    return (
      body.richCardType === MessageRichCardType.SYSTEM_CURATED_ITEM_SURVEY &&
      body.queryVariables.surveyId
    )
  }

  public constructor(props: MessageComponentProps) {
    super(props)

    this.state = { sellableToShow: 0, consecutiveSkips: 0 }
  }

  public render() {
    const { message, myself } = this.props
    const { sellableToShow, consecutiveSkips } = this.state
    const surveyId = message.parts[0].body.queryVariables.surveyId
    return (
      <StartSystemCuratedItemSurveyComponent>
        {startSystemCuratedItemSurvey => (
          <TerminateSystemCuratedItemSurveyComponent>
            {terminateSystemCuratedItemSurvey => (
              <SystemCuratedItemSurveyComponent
                variables={{ id: message.parts[0].body.queryVariables.surveyId }}
              >
                {({ data, loading }) => {
                  if (loading) {
                    return (
                      <MessageBubbleText message={message} myself={myself}>
                        Loading...
                      </MessageBubbleText>
                    )
                  } else if (data && data.systemCuratedItemSurvey) {
                    const { systemCuratedItems, ended, stats } = data.systemCuratedItemSurvey
                    const activeSystemCuratedItem = systemCuratedItems[sellableToShow]

                    return (
                      <div className={styles.messages}>
                        <MessageBubble message={message} className={styles.message}>
                          {ended && stats ? (
                            <TerminationState stats={stats} />
                          ) : activeSystemCuratedItem ? (
                            <SellableFeedbackCard
                              systemCuratedItem={activeSystemCuratedItem}
                              onProductRated={() => {
                                if (sellableToShow === 0) {
                                  startSystemCuratedItemSurvey({
                                    variables: {
                                      id: surveyId
                                    }
                                  })
                                }
                                // If there are no more sellables to show we must terminate the survey
                                //   Since setState is async we need to manually increment to be sure

                                if (!systemCuratedItems[sellableToShow + 1]) {
                                  terminateSystemCuratedItemSurvey({
                                    variables: {
                                      input: {
                                        id: surveyId,
                                        reason:
                                          SystemCuratedItemSurveyTerminationReason.REVIEWED_ALL
                                      }
                                    }
                                  })
                                }
                                this.setState(prevState => ({
                                  sellableToShow: prevState.sellableToShow + 1,
                                  consecutiveSkips: 0
                                }))
                              }}
                              onProductSkipped={() => {
                                if (sellableToShow === 0) {
                                  startSystemCuratedItemSurvey({
                                    variables: {
                                      id: surveyId
                                    }
                                  })
                                }

                                //   If that was the last item
                                if (!systemCuratedItems[sellableToShow + 1]) {
                                  terminateSystemCuratedItemSurvey({
                                    variables: {
                                      input: {
                                        id: surveyId,
                                        reason:
                                          SystemCuratedItemSurveyTerminationReason.REVIEWED_ALL
                                      }
                                    }
                                  })
                                  //   If the user skipped the first 3 sellables
                                } else if (consecutiveSkips === 2 && sellableToShow === 1) {
                                  terminateSystemCuratedItemSurvey({
                                    variables: {
                                      input: {
                                        id: surveyId,
                                        reason: SystemCuratedItemSurveyTerminationReason.SKIPPED
                                      }
                                    }
                                  })
                                  // If user has skipped 3 sellables in a row
                                } else if (consecutiveSkips === 2) {
                                  terminateSystemCuratedItemSurvey({
                                    variables: {
                                      input: {
                                        id: surveyId,
                                        reason:
                                          SystemCuratedItemSurveyTerminationReason.TERMINATED_EARLY
                                      }
                                    }
                                  })
                                }

                                this.setState(prevState => ({
                                  consecutiveSkips: prevState.consecutiveSkips + 1,
                                  sellableToShow: prevState.sellableToShow + 1
                                }))
                              }}
                            />
                          ) : null}
                        </MessageBubble>
                        {message.parts[1] && (
                          <MessageBubbleText message={message} myself={myself}>
                            {message.parts[1].body.text}
                          </MessageBubbleText>
                        )}
                      </div>
                    )
                  } else {
                    return (
                      <MessageBubbleText message={message} myself={myself}>
                        Error loading message!
                      </MessageBubbleText>
                    )
                  }
                }}
              </SystemCuratedItemSurveyComponent>
            )}
          </TerminateSystemCuratedItemSurveyComponent>
        )}
      </StartSystemCuratedItemSurveyComponent>
    )
  }
}

interface SellableFeedbackCardProps {
  systemCuratedItem: SystemCuratedItemFragment
  onProductRated: () => void
  onProductSkipped: () => void
}

const SellableFeedbackCard: React.FC<React.PropsWithChildren<SellableFeedbackCardProps>> = ({
  systemCuratedItem,
  onProductRated,
  onProductSkipped
}) => {
  const { sellable, systemCuratedItemSource } = systemCuratedItem
  const { images, title } = sellable

  const pricing = formatSellablePricing(sellable)
  const analytics = useContext(AnalyticsContext)

  return (
    <div className={styles.card}>
      <div className={styles.sellable}>
        {images.length > 0 && (
          <div className={styles.imageContainer}>
            <Image src={images[0].url} height={100} width={100} className={styles.image} />{' '}
          </div>
        )}

        <div className={styles.details}>
          <div className={styles.title}>{title}</div>
          {pricing && (
            <div className={styles.pricing}>
              {pricing.price}
              {pricing.msrp && <span className={styles.savings}>{pricing.msrp}</span>}
            </div>
          )}
          <SellableLink className={styles.viewLink} sellable={sellable}>
            View details
          </SellableLink>
        </div>
      </div>
      <SaveConsumerSellableReactionComponent
        onCompleted={() => {
          onProductRated()
        }}
      >
        {saveConsumerSellableReaction => (
          <SellableReactions
            onSellableRatingSelected={(rating: ConsumerSellableReactionRating) => {
              saveConsumerSellableReaction({
                variables: {
                  input: {
                    rating,
                    reasons: [],
                    sellableId: sellable.id,
                    recommendationSource: getRecommendationSource(systemCuratedItemSource),
                    reactionSource: ConsumerSellableReactionSource.SYSTEM_CURATED_ITEM_SURVEY
                  }
                }
              }).then(() => {
                analytics &&
                  analytics.track(
                    new ProductRatedEvent({
                      ...getSellableEcommerceProperties(sellable),
                      placement: 'product-quiz',
                      rating
                    })
                  )
              })
            }}
            onProductSkipped={onProductSkipped}
          />
        )}
      </SaveConsumerSellableReactionComponent>
    </div>
  )
}
