import { Event, ViewerContext } from '@deal/web-tracking'

export interface WishlistCreatedEventProperties {
  wishlist_id: string
}

export class WishlistCreatedEvent extends Event<WishlistCreatedEventProperties> {
  static displayName = 'Wishlist Created'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
