import {
  SerializableSearchResultsPageQuery,
  buildSearchResultsPageUrl
} from '../services/search/buildSearchResultsPageUrl'

/**
 * Serializes a `SearchQuery` into a format that can be used as a URL:
 *
 *  - The category is serialized as the URL path
 *  - The keywords, search type, and pagination cursors are serialized as top-level query params
 *  - The facets are serialized as query params with prefixes denoting their facet type.
 *      (each facet type determines its own serialization format)
 *
 * Only use if you want to link to /search page
 */
const useSearchResultsLocation = () => {
  return (query: SerializableSearchResultsPageQuery) => {
    return buildSearchResultsPageUrl(query)
  }
}

export { useSearchResultsLocation }
