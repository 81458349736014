// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { DisplayFacetFragmentDoc, BucketedDisplayFacetFragmentDoc, BucketedRangeDisplayFacetFragmentDoc, NumericRangeDisplayFacetFragmentDoc } from '../../../../fragments/Facets.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables = Types.Exact<{
  id: Types.Scalars['ExpertCuratedSearchId']['input'];
}>;


export type ExpertCuratedSearchForRichCardExpertCuratedSearchQuery = { __typename: 'Query', expertCuratedSearch?: { __typename: 'ExpertCuratedSearch', id: any, sellableSortBy: Types.SellableSort, category: { __typename: 'Category', id: any, slug: string }, decoratedFacets: Array<{ __typename: 'BucketedDisplayFacet', displayName: string, name: string, alias?: string | null, buckets: Array<{ __typename: 'IndividualBucketedDisplayFacet', displayName: string, value: string, parentValue?: string | null, count?: any | null, seoShouldFollow: boolean }> } | { __typename: 'BucketedRangeDisplayFacet', displayName: string, name: string, alias?: string | null } | { __typename: 'NumericRangeDisplayFacet', displayName: string, name: string, alias?: string | null, type: Types.NumericRangeDisplayFacetType, lower: any, upper: any, scale: number, upperCapped: boolean }> } | null };


export const ExpertCuratedSearchForRichCardExpertCuratedSearchDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExpertCuratedSearchForRichCardExpertCuratedSearch"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertCuratedSearchId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertCuratedSearch"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}},{"kind":"Field","name":{"kind":"Name","value":"decoratedFacets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DisplayFacet"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sellableSortBy"}}]}}]}},...DisplayFacetFragmentDoc.definitions,...BucketedDisplayFacetFragmentDoc.definitions,...BucketedRangeDisplayFacetFragmentDoc.definitions,...NumericRangeDisplayFacetFragmentDoc.definitions]} as unknown as DocumentNode;
export type ExpertCuratedSearchForRichCardExpertCuratedSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables>, 'query'> & ({ variables: ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpertCuratedSearchForRichCardExpertCuratedSearchComponent = (props: ExpertCuratedSearchForRichCardExpertCuratedSearchComponentProps) => (
      <ApolloReactComponents.Query<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables> query={ExpertCuratedSearchForRichCardExpertCuratedSearchDocument} {...props} />
    );
    

/**
 * __useExpertCuratedSearchForRichCardExpertCuratedSearchQuery__
 *
 * To run a query within a React component, call `useExpertCuratedSearchForRichCardExpertCuratedSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertCuratedSearchForRichCardExpertCuratedSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertCuratedSearchForRichCardExpertCuratedSearchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpertCuratedSearchForRichCardExpertCuratedSearchQuery(baseOptions: Apollo.QueryHookOptions<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables>(ExpertCuratedSearchForRichCardExpertCuratedSearchDocument, options);
      }
export function useExpertCuratedSearchForRichCardExpertCuratedSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables>(ExpertCuratedSearchForRichCardExpertCuratedSearchDocument, options);
        }
export function useExpertCuratedSearchForRichCardExpertCuratedSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables>(ExpertCuratedSearchForRichCardExpertCuratedSearchDocument, options);
        }
export type ExpertCuratedSearchForRichCardExpertCuratedSearchQueryHookResult = ReturnType<typeof useExpertCuratedSearchForRichCardExpertCuratedSearchQuery>;
export type ExpertCuratedSearchForRichCardExpertCuratedSearchLazyQueryHookResult = ReturnType<typeof useExpertCuratedSearchForRichCardExpertCuratedSearchLazyQuery>;
export type ExpertCuratedSearchForRichCardExpertCuratedSearchSuspenseQueryHookResult = ReturnType<typeof useExpertCuratedSearchForRichCardExpertCuratedSearchSuspenseQuery>;
export type ExpertCuratedSearchForRichCardExpertCuratedSearchQueryResult = Apollo.QueryResult<ExpertCuratedSearchForRichCardExpertCuratedSearchQuery, ExpertCuratedSearchForRichCardExpertCuratedSearchQueryVariables>;