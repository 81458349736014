import React from 'react'
import { CircleLoader, FullBlockLoader, Modal, ModalProps } from '@deal/components'

const ModalLoader: React.FC<{ mobilePosition?: ModalProps['mobilePosition'] }> = ({
  mobilePosition
}) => {
  return (
    <Modal isOpen={true} contentLabel="Modal Loader" mobilePosition={mobilePosition}>
      <FullBlockLoader loader={CircleLoader} />
    </Modal>
  )
}

export default ModalLoader
