// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateWishlistForCreateWishlistFormMutationVariables = Types.Exact<{
  title: Types.Scalars['String']['input'];
  addressId?: Types.InputMaybe<Types.Scalars['AddressId']['input']>;
}>;


export type CreateWishlistForCreateWishlistFormMutation = { __typename: 'Mutation', createWishlist: { __typename: 'CreateWishlistPayload', wishlist?: { __typename: 'Wishlist', id: any } | null } };

export type AddAddressWithValidationForCreateWishlistFormMutationVariables = Types.Exact<{
  input: Types.AddAddressWithValidationInput;
}>;


export type AddAddressWithValidationForCreateWishlistFormMutation = { __typename: 'Mutation', addAddressWithValidation: { __typename: 'AddAddressWithValidationPayload', address?: { __typename: 'Address', id: any } | null, error?: { __typename: 'InvalidAddressAddAddressWithValidationError' } | null } };

export type DeleteAddressForCreateWishlistFormMutationVariables = Types.Exact<{
  input: Types.DeleteAddressInput;
}>;


export type DeleteAddressForCreateWishlistFormMutation = { __typename: 'Mutation', deleteAddress: { __typename: 'DeleteAddressPayload', success?: boolean | null } };


export const CreateWishlistForCreateWishlistFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateWishlistForCreateWishlistForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"addressId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AddressId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createWishlist"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"addressId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"addressId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wishlist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CreateWishlistForCreateWishlistFormMutationFn = Apollo.MutationFunction<CreateWishlistForCreateWishlistFormMutation, CreateWishlistForCreateWishlistFormMutationVariables>;
export type CreateWishlistForCreateWishlistFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateWishlistForCreateWishlistFormMutation, CreateWishlistForCreateWishlistFormMutationVariables>, 'mutation'>;

    export const CreateWishlistForCreateWishlistFormComponent = (props: CreateWishlistForCreateWishlistFormComponentProps) => (
      <ApolloReactComponents.Mutation<CreateWishlistForCreateWishlistFormMutation, CreateWishlistForCreateWishlistFormMutationVariables> mutation={CreateWishlistForCreateWishlistFormDocument} {...props} />
    );
    

/**
 * __useCreateWishlistForCreateWishlistFormMutation__
 *
 * To run a mutation, you first call `useCreateWishlistForCreateWishlistFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWishlistForCreateWishlistFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWishlistForCreateWishlistFormMutation, { data, loading, error }] = useCreateWishlistForCreateWishlistFormMutation({
 *   variables: {
 *      title: // value for 'title'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useCreateWishlistForCreateWishlistFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateWishlistForCreateWishlistFormMutation, CreateWishlistForCreateWishlistFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWishlistForCreateWishlistFormMutation, CreateWishlistForCreateWishlistFormMutationVariables>(CreateWishlistForCreateWishlistFormDocument, options);
      }
export type CreateWishlistForCreateWishlistFormMutationHookResult = ReturnType<typeof useCreateWishlistForCreateWishlistFormMutation>;
export type CreateWishlistForCreateWishlistFormMutationResult = Apollo.MutationResult<CreateWishlistForCreateWishlistFormMutation>;
export type CreateWishlistForCreateWishlistFormMutationOptions = Apollo.BaseMutationOptions<CreateWishlistForCreateWishlistFormMutation, CreateWishlistForCreateWishlistFormMutationVariables>;
export const AddAddressWithValidationForCreateWishlistFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddAddressWithValidationForCreateWishlistForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddAddressWithValidationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addAddressWithValidation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AddAddressWithValidationForCreateWishlistFormMutationFn = Apollo.MutationFunction<AddAddressWithValidationForCreateWishlistFormMutation, AddAddressWithValidationForCreateWishlistFormMutationVariables>;
export type AddAddressWithValidationForCreateWishlistFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAddressWithValidationForCreateWishlistFormMutation, AddAddressWithValidationForCreateWishlistFormMutationVariables>, 'mutation'>;

    export const AddAddressWithValidationForCreateWishlistFormComponent = (props: AddAddressWithValidationForCreateWishlistFormComponentProps) => (
      <ApolloReactComponents.Mutation<AddAddressWithValidationForCreateWishlistFormMutation, AddAddressWithValidationForCreateWishlistFormMutationVariables> mutation={AddAddressWithValidationForCreateWishlistFormDocument} {...props} />
    );
    

/**
 * __useAddAddressWithValidationForCreateWishlistFormMutation__
 *
 * To run a mutation, you first call `useAddAddressWithValidationForCreateWishlistFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressWithValidationForCreateWishlistFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressWithValidationForCreateWishlistFormMutation, { data, loading, error }] = useAddAddressWithValidationForCreateWishlistFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAddressWithValidationForCreateWishlistFormMutation(baseOptions?: Apollo.MutationHookOptions<AddAddressWithValidationForCreateWishlistFormMutation, AddAddressWithValidationForCreateWishlistFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAddressWithValidationForCreateWishlistFormMutation, AddAddressWithValidationForCreateWishlistFormMutationVariables>(AddAddressWithValidationForCreateWishlistFormDocument, options);
      }
export type AddAddressWithValidationForCreateWishlistFormMutationHookResult = ReturnType<typeof useAddAddressWithValidationForCreateWishlistFormMutation>;
export type AddAddressWithValidationForCreateWishlistFormMutationResult = Apollo.MutationResult<AddAddressWithValidationForCreateWishlistFormMutation>;
export type AddAddressWithValidationForCreateWishlistFormMutationOptions = Apollo.BaseMutationOptions<AddAddressWithValidationForCreateWishlistFormMutation, AddAddressWithValidationForCreateWishlistFormMutationVariables>;
export const DeleteAddressForCreateWishlistFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteAddressForCreateWishlistForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteAddressInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteAddress"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode;
export type DeleteAddressForCreateWishlistFormMutationFn = Apollo.MutationFunction<DeleteAddressForCreateWishlistFormMutation, DeleteAddressForCreateWishlistFormMutationVariables>;
export type DeleteAddressForCreateWishlistFormComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAddressForCreateWishlistFormMutation, DeleteAddressForCreateWishlistFormMutationVariables>, 'mutation'>;

    export const DeleteAddressForCreateWishlistFormComponent = (props: DeleteAddressForCreateWishlistFormComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAddressForCreateWishlistFormMutation, DeleteAddressForCreateWishlistFormMutationVariables> mutation={DeleteAddressForCreateWishlistFormDocument} {...props} />
    );
    

/**
 * __useDeleteAddressForCreateWishlistFormMutation__
 *
 * To run a mutation, you first call `useDeleteAddressForCreateWishlistFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressForCreateWishlistFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressForCreateWishlistFormMutation, { data, loading, error }] = useDeleteAddressForCreateWishlistFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAddressForCreateWishlistFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddressForCreateWishlistFormMutation, DeleteAddressForCreateWishlistFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAddressForCreateWishlistFormMutation, DeleteAddressForCreateWishlistFormMutationVariables>(DeleteAddressForCreateWishlistFormDocument, options);
      }
export type DeleteAddressForCreateWishlistFormMutationHookResult = ReturnType<typeof useDeleteAddressForCreateWishlistFormMutation>;
export type DeleteAddressForCreateWishlistFormMutationResult = Apollo.MutationResult<DeleteAddressForCreateWishlistFormMutation>;
export type DeleteAddressForCreateWishlistFormMutationOptions = Apollo.BaseMutationOptions<DeleteAddressForCreateWishlistFormMutation, DeleteAddressForCreateWishlistFormMutationVariables>;