import React from 'react'
import capitalize from 'lodash/capitalize'
import classNames from 'classnames'
import {
  ConsumerSellableReactionRating,
  ConsumerSellableReactionRatingWithDisplayName,
} from "#src/generated/types";
import { Hate, Like, Love } from './reactions'
import styles from './styles.css'

interface SellableReactionsProps {
  isDisabled?: boolean
  selectedRating?: ConsumerSellableReactionRatingWithDisplayName | null
  hideSkip?: boolean
  hideText?: boolean
  buttonClassName?: string
  onProductSkipped?: () => void
  onProductDisliked?: () => void
  onSellableRatingSelected: (reactionType: ConsumerSellableReactionRating) => void
}

interface SellableReactionButton extends SellableReactionsProps {
  buttonRating: ConsumerSellableReactionRating
  Icon?: React.FunctionComponent<React.PropsWithChildren<React.SVGAttributes<SVGElement>>>
}

const SellableReactionButton: React.FC<React.PropsWithChildren<SellableReactionButton>> = ({
  buttonRating,
  Icon,
  isDisabled,
  selectedRating,
  onSellableRatingSelected,
  hideText,
  onProductDisliked,
  onProductSkipped,
  buttonClassName
}) => {
  const sellableRating = selectedRating && selectedRating.rating
  const isSelected = sellableRating === buttonRating
  const displayText = capitalize(buttonRating)

  // when a reaction is selected, remove icon. if text is hidden, always show icon.
  let buttonContents
  if (hideText && Icon) {
    buttonContents = <Icon />
  } else if (buttonRating === sellableRating) {
    buttonContents = displayText
  } else {
    buttonContents = (
      <>
        {Icon && <Icon className={styles.icon} />}
        {displayText}
      </>
    )
  }

  return (
    <button
      disabled={isDisabled}
      className={classNames(styles.ratingButton, buttonClassName, {
        [styles.selected]: isSelected
      })}
      onClick={() => {
        onProductSkipped ? onProductSkipped() : onSellableRatingSelected(buttonRating)
        onProductDisliked && onProductDisliked()
      }}
      title={displayText}
      data-testid={
        buttonRating === ConsumerSellableReactionRating.LOVE
          ? 'sellable-reaction-buttons'
          : undefined
      }
    >
      {buttonContents}
    </button>
  )
}

const SellableReactions: React.FC<React.PropsWithChildren<SellableReactionsProps>> = ({
  onProductDisliked,
  onProductSkipped,
  hideSkip,
  ...props
}) => {
  return (
    <div className={styles.ratingsContainer}>
      <SellableReactionButton
        buttonRating={ConsumerSellableReactionRating.LOVE}
        Icon={Love}
        {...props}
      />
      <SellableReactionButton
        buttonRating={ConsumerSellableReactionRating.LIKE}
        Icon={Like}
        {...props}
      />
      <SellableReactionButton
        buttonRating={ConsumerSellableReactionRating.DISLIKE}
        onProductDisliked={onProductDisliked}
        Icon={Hate}
        {...props}
      />
      {!hideSkip && (
        <SellableReactionButton
          buttonRating={ConsumerSellableReactionRating.SKIP}
          onProductSkipped={onProductSkipped}
          {...props}
        />
      )}
    </div>
  )
}

export default SellableReactions
