import React, { useState } from 'react'
import xor from 'lodash/xor'
import classnames from 'classnames'
import { Button } from '@deal/components'
import {
  ConsumerSellableReactionRating,
  ConsumerSellableReactionReason,
  ConsumerSellableReactionRecommendationSourceInput,
  ConsumerSellableReactionSource
} from '#src/generated/types'
import { SystemCuratedItemSourceFragment } from '../SystemCuratedItem.generated'
import { SaveConsumerSellableReactionComponent } from './SaveConsumerSellableReactions.generated'
import styles from './styles.css'

interface Props {
  recommendationSource: SystemCuratedItemSourceFragment
  sellableId: string
  rating: ConsumerSellableReactionRating
  reactionSource: ConsumerSellableReactionSource
  onCompleted: () => void
}

const getTitle = (rating: ConsumerSellableReactionRating) => {
  switch (rating) {
    case ConsumerSellableReactionRating.DISLIKE:
      return 'dislike'
    case ConsumerSellableReactionRating.LIKE:
      return 'like'
    case ConsumerSellableReactionRating.LOVE:
      return 'love'
  }
}

export const getRecommendationSource = (
  recommendationSource: SystemCuratedItemSourceFragment
): ConsumerSellableReactionRecommendationSourceInput | null => {
  switch (recommendationSource.__typename) {
    case 'PopularExpertCuratedItemFromSimilarUserSystemCuratedItemSource':
      return { expertCuratedItemId: recommendationSource.expertCuratedItem.id }
    case 'RecentExpertCuratedItemFromSimilarUserSystemCuratedItemSource':
      return { expertCuratedItemId: recommendationSource.expertCuratedItem.id }
    case 'TopRecommendedListSystemCuratedItemSource':
      return { recommendedListDefinitionId: recommendationSource.recommendedListDefinition.id }
    case 'TopRecommendationAndSimilarUserSystemCuratedItemSource':
      return recommendationSource.recentExpertCuratedItemFromSimilarUserSource
        ? {
            expertCuratedItemId:
              recommendationSource.recentExpertCuratedItemFromSimilarUserSource.expertCuratedItem.id
          }
        : recommendationSource.topRecommendedListSystemCuratedItemSource
        ? {
            recommendedListDefinitionId:
              recommendationSource.topRecommendedListSystemCuratedItemSource
                .recommendedListDefinition.id
          }
        : null
  }
}

const ReactionFeedback: React.FC<React.PropsWithChildren<Props>> = ({
  recommendationSource,
  sellableId,
  rating,
  reactionSource,
  onCompleted
}) => {
  const [reasons, setReasons] = useState<ConsumerSellableReactionReason[]>([])

  return (
    <SaveConsumerSellableReactionComponent onCompleted={onCompleted}>
      {(saveConsumerSellableReaction, { loading }) => {
        return (
          <div className={styles.overlay}>
            <div className={styles.title}>
              Tell us why you <strong>{getTitle(rating)}</strong> it
            </div>
            <div className={styles.reasons}>
              <Button
                variant="neutral-light-ghost"
                size="small"
                className={classnames(styles.reasonButton, {
                  [styles.selected]: reasons.includes(ConsumerSellableReactionReason.PRICE)
                })}
                onClick={() => setReasons(xor(reasons, [ConsumerSellableReactionReason.PRICE]))}
              >
                Price
              </Button>
              <Button
                variant="neutral-light-ghost"
                size="small"
                className={classnames(styles.reasonButton, {
                  [styles.selected]: reasons.includes(ConsumerSellableReactionReason.STYLE)
                })}
                onClick={() => setReasons(xor(reasons, [ConsumerSellableReactionReason.STYLE]))}
              >
                Style
              </Button>
              <Button
                variant="neutral-light-ghost"
                size="small"
                className={classnames(styles.reasonButton, {
                  [styles.selected]: reasons.includes(ConsumerSellableReactionReason.BRAND)
                })}
                onClick={() => setReasons(xor(reasons, [ConsumerSellableReactionReason.BRAND]))}
              >
                Brand
              </Button>
              <Button
                variant="neutral-light-ghost"
                size="small"
                className={classnames(styles.reasonButton, {
                  [styles.selected]: reasons.includes(ConsumerSellableReactionReason.FEATURES)
                })}
                onClick={() => setReasons(xor(reasons, [ConsumerSellableReactionReason.FEATURES]))}
              >
                Features
              </Button>
            </div>
            <div className={styles.submitRow}>
              <Button
                size="xsmall"
                disabled={loading}
                onClick={() => {
                  saveConsumerSellableReaction({
                    variables: {
                      input: {
                        rating,
                        reasons: [],
                        sellableId,
                        recommendationSource: getRecommendationSource(recommendationSource),
                        reactionSource: reactionSource
                      }
                    }
                  })
                }}
                className={styles.buttonClear}
              >
                Skip
              </Button>
              <Button
                disabled={!reasons.length || loading}
                variant="primary"
                size="xsmall"
                onClick={() => {
                  saveConsumerSellableReaction({
                    variables: {
                      input: {
                        rating,
                        reasons,
                        sellableId,
                        recommendationSource: getRecommendationSource(recommendationSource),
                        reactionSource: reactionSource
                      }
                    }
                  })
                }}
              >
                Done
              </Button>
            </div>
          </div>
        )
      }}
    </SaveConsumerSellableReactionComponent>
  )
}

export default ReactionFeedback
