// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AddMembershipToOrderMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type AddMembershipToOrderMutation = { __typename: 'Mutation', addMembershipToOrder: { __typename: 'AddMembershipToOrderPayload', order: { __typename: 'Order', id: any }, error?: { __typename: 'AlreadyMemberAddMembershipToOrderError', errorMessage: string } | { __typename: 'MembershipAlreadyInCartAddMembershipToOrderError', errorMessage: string } | { __typename: 'NoMembershipTypeAvailableAddMembershipToOrderError', errorMessage: string } | null } };


export const AddMembershipToOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddMembershipToOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addMembershipToOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"order"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AlreadyMemberAddMembershipToOrderError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MembershipAlreadyInCartAddMembershipToOrderError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"NoMembershipTypeAvailableAddMembershipToOrderError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type AddMembershipToOrderMutationFn = Apollo.MutationFunction<AddMembershipToOrderMutation, AddMembershipToOrderMutationVariables>;
export type AddMembershipToOrderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMembershipToOrderMutation, AddMembershipToOrderMutationVariables>, 'mutation'>;

    export const AddMembershipToOrderComponent = (props: AddMembershipToOrderComponentProps) => (
      <ApolloReactComponents.Mutation<AddMembershipToOrderMutation, AddMembershipToOrderMutationVariables> mutation={AddMembershipToOrderDocument} {...props} />
    );
    

/**
 * __useAddMembershipToOrderMutation__
 *
 * To run a mutation, you first call `useAddMembershipToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembershipToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembershipToOrderMutation, { data, loading, error }] = useAddMembershipToOrderMutation({
 *   variables: {
 *   },
 * });
 */
export function useAddMembershipToOrderMutation(baseOptions?: Apollo.MutationHookOptions<AddMembershipToOrderMutation, AddMembershipToOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMembershipToOrderMutation, AddMembershipToOrderMutationVariables>(AddMembershipToOrderDocument, options);
      }
export type AddMembershipToOrderMutationHookResult = ReturnType<typeof useAddMembershipToOrderMutation>;
export type AddMembershipToOrderMutationResult = Apollo.MutationResult<AddMembershipToOrderMutation>;
export type AddMembershipToOrderMutationOptions = Apollo.BaseMutationOptions<AddMembershipToOrderMutation, AddMembershipToOrderMutationVariables>;