import React from 'react'
import { toast } from 'react-toastify'
import { ConsumerRichCardEvents, useRichCardEventEmitter } from '@deal/chat-firebase'
import { useCartVisibilityContext } from '#src/app/context/cart-visibility'
import { useAddMembershipToOrderMutation } from './AddMembershipToOrder.generated'

const AddMembershipToOrder: React.FC<React.PropsWithChildren<unknown>> = () => {
  const richCardEventEmitter = useRichCardEventEmitter()
  const { setCartVisibility } = useCartVisibilityContext()
  const [addMembershipToOrder] = useAddMembershipToOrderMutation({
    onCompleted: ({ addMembershipToOrder }) => {
      if (addMembershipToOrder.error) {
        toast.error(addMembershipToOrder.error.errorMessage)
      } else {
        // Open the cart on a successful addition of the membership to the cart
        setCartVisibility(true)
      }
    }
  })

  richCardEventEmitter?.addListener(ConsumerRichCardEvents.ADD_MEMBERSHIP_TO_ORDER, () => {
    addMembershipToOrder()
  })

  return null
}

export default AddMembershipToOrder
