import React, { useState } from 'react'
import { ConsumerTracking } from '@deal/web-tracking/types'
import { ConsumerRichCardEvents, useRichCardEventEmitter } from '@deal/chat-firebase'
import ExpertReferralModal from '#src/app/components/ExpertReferralModal'

const RichCardReferralModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [businessUserId, setBusinessUserId] = useState<string | undefined>()
  const [entryPoint, setEntryPoint] = useState<ConsumerTracking.EntryPoint | undefined>()

  const richCardEventEmitter = useRichCardEventEmitter()

  richCardEventEmitter?.addListener(ConsumerRichCardEvents.OPEN_REFERRAL_MODAL, eventData => {
    setIsModalOpen(true)
    setBusinessUserId(eventData.businessUserId)
    setEntryPoint(eventData.entryPoint as ConsumerTracking.EntryPoint)
  })

  if (isModalOpen && businessUserId && entryPoint) {
    return (
      <ExpertReferralModal
        entryPoint={entryPoint}
        onRequestClose={() => setIsModalOpen(false)}
        businessUserId={businessUserId}
      />
    )
  }

  return null
}

export default RichCardReferralModal
