import { ConsumerTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ProductRatedEventProperties extends ConsumerTracking.SellableProperties {
  placement: string
  rating: string
}

export class ProductRatedEvent extends Event<ProductRatedEventProperties> {
  static displayName = 'Product Rated'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
