import React from 'react'
import capitalize from 'lodash/capitalize'
import { Link } from '@deal/router'
import { SystemCuratedConsumerSellableReactionRating } from '#src/generated/types'
import { SystemCuratedItemSurveyStatsFragment } from '../SystemCuratedItemSurveyStats.generated'
import SkipIcon from './skip.svg'
import LoveIcon from './love.svg'
import LikeIcon from './like.svg'
import HateIcon from './hate.svg'
import styles from './styles.css'

interface Props {
  stats: SystemCuratedItemSurveyStatsFragment
}

const getIcon = (rating: SystemCuratedConsumerSellableReactionRating) => {
  switch (rating) {
    case SystemCuratedConsumerSellableReactionRating.DISLIKE:
      return <HateIcon className={styles.icon} />
    case SystemCuratedConsumerSellableReactionRating.LIKE:
      return <LikeIcon className={styles.icon} />
    case SystemCuratedConsumerSellableReactionRating.LOVE:
      return <LoveIcon className={styles.icon} />
    case SystemCuratedConsumerSellableReactionRating.SKIP:
      return <SkipIcon className={styles.icon} />
  }
}

const TerminationState: React.FC<React.PropsWithChildren<Props>> = ({ stats }) => {
  return (
    <div>
      <div className={styles.content}>
        <div className={styles.title}>That's all!</div>
        <div className={styles.text}>
          We sent your feedback to your expert – this will help them find exactly what you’re
          looking for! Here’s a summary of your responses:
        </div>
        <div className={styles.stats}>
          {stats.ratingStats.map(stat => (
            <div className={styles.stat} key={stat.rating}>
              {getIcon(stat.rating)}{' '}
              <div className={styles.rating}>
                <div className={styles.count}>{stat.count}</div> {capitalize(stat.rating)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Link to="/favorites" className={styles.cta}>
        See your favorites
      </Link>
    </div>
  )
}

export default TerminationState
