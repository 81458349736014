import React, { useState } from 'react'
import { ConsumerTracking } from '@deal/web-tracking/types'
import { ConsumerRichCardEvents, useRichCardEventEmitter } from '@deal/chat-firebase'
import ExpertRatingModal from '#src/app/components/ExpertRatingModal'

const RichCardExpertRatingModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [businessUserId, setBusinessUserId] = useState<string | undefined>()
  const [entryPoint, setEntryPoint] = useState<ConsumerTracking.EntryPoint | undefined>()

  const richCardEventEmitter = useRichCardEventEmitter()

  richCardEventEmitter?.addListener(ConsumerRichCardEvents.OPEN_EXPERT_RATING_MODAL, eventData => {
    setIsModalOpen(true)
    setBusinessUserId(eventData.businessUserId)
    setEntryPoint(eventData.entryPoint as ConsumerTracking.EntryPoint)
  })

  if (isModalOpen && businessUserId && entryPoint) {
    return (
      <>
        {isModalOpen && (
          <ExpertRatingModal
            onCloseModal={() => setIsModalOpen(false)}
            onPathCompleted={() => setIsModalOpen(false)}
            expertId={businessUserId}
            entryPoint={entryPoint}
          />
        )}
      </>
    )
  }

  return null
}

export default RichCardExpertRatingModal
